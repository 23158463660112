import { gql } from '@apollo/client';

export const CURRENT_USER = gql`
query CurrentUser {
  currentUser {
    id
    title
    firstname
    lastname
    photo
    email
    phone
    status
    gender
    role_name
    role_id
    permissions
    created_at
  }
}
`
export const GET_CONFIG = gql`
    query Query {
  getConfig
}
`
export const GET_USERS = gql`
query GetUsers {
  getUsers {
    id
    title
    firstname
    lastname
    photo
    email
    phone
    status
    gender
    role_name
    role_id
    permissions
    created_at
  }
}
`
export const GET_ROLES = gql`
    query GetRoles($offset: Int) {
  getRoles(offset: $offset) {
    id
    name
    json
    status
  }
}
`
export const GET_ALL_ASSET_CATEGORIES = gql`
 query GetAllAssetCategories($offset: Int) {
  getAllAssetCategories(offset: $offset) {
    id
    name
    description
    depreciation_method
    useful_life_years
    salvage_value
    created_at
    updated_at
  }
}
`
export const GET_ALL_ASSET_ITEMS = gql`
 query GetAllAssetsItems($offset: Int) {
  getAllAssetsItems(offset: $offset) {
    id
    vendor_id
    location_id
    category_id
    tag
    serial_number
    name
    description
    purchase_date
    purchase_cost
    status
    created_at
    updated_at
  }
}
`
export const GET_ASSET_BY_CATEGORY = gql`
  query GetAssetsByCategory($status: AssetStatus, $categoryId: Int, $offset: Int) {
  getAssetsByCategory(status: $status, category_id: $categoryId, offset: $offset) {
    id
    vendor_id
    location_id
    category_id
    tag
    serial_number
    name
    description
    purchase_date
    purchase_cost
    status
    created_at
    updated_at
  }
}
`
export const GET_ASSET_BY_LOCATION = gql`
  query GetAssetsByLocation($status: AssetStatus, $locationId: Int, $offset: Int) {
  getAssetsByLocation(status: $status, location_id: $locationId, offset: $offset) {
    id
    vendor_id
    location_id
    category_id
    tag
    serial_number
    name
    description
    purchase_date
    purchase_cost
    status
    created_at
    updated_at
  }
}
`
export const GET_ASSET_BY_VENDOR = gql`
  query GetAssetsByVendor($status: AssetStatus, $vendorId: Int, $offset: Int) {
  getAssetsByVendor(status: $status, vendor_id: $vendorId, offset: $offset) {
    id
    vendor_id
    location_id
    category_id
    tag
    serial_number
    name
    description
    purchase_date
    purchase_cost
    status
    created_at
    updated_at
  }
}
`
export const GET_ASSETS_BY_PURCHASE_DATE = gql`
  query GetAssetsByPurchaseDate($status: AssetStatus, $purchaseDateStart: String, $purchaseDateEnd: String, $offset: Int) {
  getAssetsByPurchaseDate(status: $status, purchase_date_start: $purchaseDateStart, purchase_date_end: $purchaseDateEnd, offset: $offset) {
    id
    vendor_id
    location_id
    category_id
    tag
    serial_number
    name
    description
    purchase_date
    purchase_cost
    status
    created_at
    updated_at
  }
}
`
export const GET_ASSET_DEPRECIATION = gql`
    query GetAssetDepreciation($assetId: Int, $offset: Int) {
  getAssetDepreciation(asset_id: $assetId, offset: $offset) {
    id
    item_id
    tx_code
    depreciation_method
    depreciation_amount
    created_at
  }
}
`
export const GET_LOCATIONS = gql`
    query GetLocations($offset: Int) {
  getLocations(offset: $offset) {
    id
    name
    description
    created_at
    updated_at
  }
}
`
export const GET_LOCATION = gql`
    query GetLocation($getLocationId: Int) {
  getLocation(id: $getLocationId) {
    id
    name
    description
  }
}
`
export const GET_DOCUMENT_BY_ID = gql`
  query GetDocumentById($getDocumentByIdId: Int!) {
  getDocumentById(id: $getDocumentByIdId) {
    id
    type
    name
    description
    attachments {
      filename
      filepath
    }
    created_at
    updated_at
  }
}
`
export const GET_ALL_DOCUMENTS = gql`
  query GetAllDocuments($offset: Int) {
  getAllDocuments(offset: $offset) {
    id
    type
    name
    description
    attachments {
      filename
      filepath
    }
    created_at
    updated_at
  }
}
`
export const GET_EMAIL_BY_ID = gql`
query GetEmailById($getEmailByIdId: Int!) {
  getEmailById(id: $getEmailByIdId) {
    id
    payload
    status
    created_at
  }
}
`
export const GET_ALL_EMAILS = gql`
    query GetAllEmails($offset: Int!) {
  getAllEmails(offset: $offset) {
    id
    payload
    status
    created_at
  }
}
`
export const GET_ACCOUNT = gql`
    query GetAccount($getAccountId: Int) {
  getAccount(id: $getAccountId) {
    id
    location_id
    name
    details
    type
    category
    balance
    created_at
    updated_at
  }
}
`
export const GET_ACCOUNTS = gql`
  query GetAccounts($offset: Int, $locationId: Int) {
  getAccounts(offset: $offset, location_id: $locationId) {
    id
    location_id
    name
    details
    type
    category
    balance
    created_at
    updated_at
  }
}
`
export const GET_CUSTOMER_ACCOUNTS_FOR_LOCATION = gql`
    query GetCustomerAccountsForLocation($offset: Int, $locationId: Int) {
  getCustomerAccountsForLocation(offset: $offset, location_id: $locationId) {
    id
    location_id
    name
    details
    type
    category
    balance
    created_at
    updated_at
  }
}
`
export const GET_ACCOUNTS_FOR_LOCATION = gql`
    query GetAccountsForLocation($offset: Int, $locationId: Int) {
  getAccountsForLocation(offset: $offset, location_id: $locationId) {
    id
    location_id
    name
    details
    type
    category
    balance
    created_at
    updated_at
  }
}
`
export const GET_ACCOUNTS_FOR_LOCATION_FOR_INVOICE_MODAL = gql`
    query GetAccountsForLocation($offset: Int, $locationId: Int) {
  getAccountsForLocation(offset: $offset, location_id: $locationId) {
    id
    name
    details
  }
}
`
export const GET_ACCOUNTS_BY_TYPE = gql`
    query GetAccountsByType($offset: Int, $type: AccountType, $locationId: Int) {
  getAccountsByType(offset: $offset, type: $type, location_id: $locationId) {
    id
    location_id
    name
    details
    type
    category
    balance
    created_at
    updated_at
  }
}
`
export const GET_ACCOUNTS_BY_CATEGORY = gql`
    query GetAccountsByCategory($offset: Int, $category: AccountCategory, $locationId: Int) {
  getAccountsByCategory(offset: $offset, category: $category, location_id: $locationId) {
    id
    location_id
    name
    details
    type
    category
    balance
    created_at
    updated_at
  }
}
`
export const GET_ALL_ACCOUNTING_YEARS = gql`
    query GetAllAccountingYears($offset: Int) {
  getAllAccountingYears(offset: $offset) {
    id
    name
    start_date
    end_date
    status
    created_at
    updated_at
  }
}
`
export const GET_CATEGORIES = gql`
    query GetCategories($offset: Int) {
  getCategories(offset: $offset) {
    id
    name
    description
    created_at
    updated_at
  }
}
`
export const GET_CATEGORY_BY_ID = gql`
    query GetCategoryById($getCategoryByIdId: Int!) {
  getCategoryById(id: $getCategoryByIdId) {
    id
    name
    description
    created_at
    updated_at
  }
}
`
export const GET_PRODUCTS = gql`
query GetProducts($offset: Int) {
  getProducts(offset: $offset) {
    id
    category_id
    balance
    photo
    reorder_level
    code
    unit
    serial_number
    name
    description
    status
    created_at
    updated_at
    category_name
  }
}
`
export const GET_PRODUCTS_FOR_INVOICE = gql`
query GetProductBatchesForLocation($offset: Int, $locationId: Int) {
  getProductBatchesForLocation(offset: $offset, location_id: $locationId) {
    id
    location_id
    product_id
    transaction_id
    staff_id
    invoice_id
    batch_number
    quantity_in
    balance
    unit_cost
    sale_price
    cost_price
    remarks
    date_bought
    date_sold
    created_at
    updated_at
    total_quantity
    total_balance
    location_name
    product_name
    invoice_reference_code
    staff_name
  }
}
`
export const GET_BARE_PRODUCTS = gql`
    query GetBareProducts {
  getBareProducts {
    id
    name
    unit
  }
}`
export const GET_PRODUCT_BY_ID = gql`
    query GetProductById($getProductByIdId: Int!) {
  getProductById(id: $getProductByIdId) {
    id
    category_id
    balance
    photo
    reorder_level
    code
    batch_number
    unit
    serial_number
    name
    description
    status
    created_at
    updated_at
  }
}
`
export const GET_BATCHES = gql`
    query GetBatches {
  getBatches {
    id
    location_id
    product_id
    transaction_id
    quantity
    invoice_id
    unit_cost
    batch_no
    cost_price
    staff_id
    date_bought
    date_sold
  }
}
`

export const GET_PRODUCT_BATCHES = gql`
query GetProductBatches($offset: Int) {
  getProductBatches(offset: $offset) {
    id
    location_id
    product_id
    transaction_id
    invoice_id
    unit_cost
    sale_price
    cost_price
    staff_id
    date_bought
    date_sold
    balance
    created_at
    batch_number
    quantity_in
    remarks
    total_balance
    total_quantity
    updated_at
    product_name
    location_name
    invoice_reference_code
    staff_name
  }
}
`


export const GET_BATCH_BY_ID = gql`
    query GetBatchById($getBatchByIdId: Int!) {
  getBatchById(id: $getBatchByIdId) {
    id
    location_id
    product_id
    transaction_id
    quantity
    invoice_id
    unit_cost
    batch_no
    cost_price
    staff_id
    date_bought
    date_sold
  }
}
`
export const GET_TRANSACTIONS = gql`
    query GetTransactions($offset: Int) {
  getTransactions(offset: $offset) {
    id
    location_id
    product_id
    customer_account_id
    customer_name
    staff_id
    vendor_account_id
    vendor_name
    accounting_year_id
    transaction_type
    type
    account_name
    code
    quantity
    unit_cost
    cost_price
    total_price
    profit
    remarks
    created_at
    updated_at
    staff_name
    location_name
    product_name
  }
}
`

export const GET_VENDOR_ACCOUNTS_FOR_LOCATION = gql`query GetVendorAccountsForLocation($locationId: Int) {
  getVendorAccountsForLocation(location_id: $locationId) {
    id
    location_id
    name
    details
    type
    category
    balance
    created_at
    updated_at
  }
}
`

export const GET_TRANSACTION_BY_ID = gql`
  query GetTransactionById($getTransactionByIdId: Int!) {
  getTransactionById(id: $getTransactionByIdId) {
    id
    location_id
    product_id
    customer_account_id
    staff_id
    vendor_account_id
    accounting_year_id
    transaction_type
    type
    code
    quantity
    unit_cost
    cost_price
    total_price
    profit
    remarks
    created_at
    updated_at
  }
}
`
export const GET_ALL_INVOICES = gql`
    query GetAllInvoices($offset: Int) {
  getAllInvoices(offset: $offset) {
    id
    account_id
    account_name
    reference_code
    date
    delivery_date
    product_list
    remarks
    status
    pending_list
    total_cost
    created_at
    updated_at
  }
}
`
export const GET_UNBATCHED_VENDOR_INVOICES = gql`
query GetUnbatchedPurchaseInvoices {
  getUnbatchedPurchaseInvoices {
    id
    account_id
    account_name
    reference_code
    date
    delivery_date
    status
    pending_list
    product_list
  }
}
`

export const GET_SALES_INVOICES = gql`
query GetSalesInvoices($offset: Int) {
  getSalesInvoices(offset: $offset) {
    id
    account_id
    account_name
    reference_code
    date
    delivery_date
    product_list
    remarks
    status
    pending_list
    total_cost
    created_at
    updated_at
  }
}
`

export const GET_INVOICE = gql`
    query GetInvoice($getInvoiceId: Int) {
  getInvoice(id: $getInvoiceId) {
    id
    account_id
    reference_code
    date
    delivery_date
    product_list
    remarks
    status
    pending_list
    total_cost
    created_at
    updated_at
  }
}
`
export const GET_INVOICE_BY_ACCOUNT = gql`
    query GetInvoiceByAccount($offset: Int, $accountId: Int) {
  getInvoiceByAccount(offset: $offset, account_id: $accountId) {
    id
    account_id
    reference_code
    date
    delivery_date
    product_list
    remarks
    status
    pending_list
    total_cost
    created_at
    updated_at
  }
}
`
export const GET_INVOICE_BY_STATUS = gql`
    query GetInvoicesByStatus($offset: Int, $status: InvoiceStatus) {
  getInvoicesByStatus(offset: $offset, status: $status) {
    id
    account_id
    reference_code
    date
    delivery_date
    product_list
    remarks
    status
    pending_list
    total_cost
    created_at
    updated_at
  }
}
`
export const GET_ALL_STOCK_ALERTS = gql`
  query GetAllStockAlerts {
    getAllStockAlerts {
      product_name
      location_name
      balance
      reorder_level
      warning_level
      message
    }
  }
`;

export const GET_LOCATION_STOCK_ALERTS = gql`
 query GetLocationStockAlerts($locationId: Int) {
  getLocationStockAlerts(location_id: $locationId) {
    product_id
    product_name
    reorder_level
    balance
    message
    location_id
    location_name
    warning_level
  }
}
`;

export const GET_CHART_PRODUCTS = gql`
query GetChartProducts {
  getChartProducts {
    id
    name
    balance
  }
}`
export const GET_INVOICE_BY_REFERNCE_CODE = gql`
query GetInvoiceByReferenceCode($referenceCode: String!) {
  getInvoiceByReferenceCode(reference_code: $referenceCode) {
    id
    account_id
    reference_code
    date
    delivery_date
    product_list
    remarks
    status
    type
    batched
    pending_list
    total_cost
    created_at
    updated_at
  }
}`
export const GET_INCOMPLETE_PURCHASES = gql`
query GetIncompleteSales($offset: Int) {
  getIncompleteSales(offset: $offset) {
    id
    account_id
    account_name
    reference_code
    date
    delivery_date
    product_list
    remarks
    status
    pending_list
    total_cost
    paid
    balance
    created_at
    updated_at
  }
}`
export const GET_INCOMPLETE_SALES = gql`
query GetIncompleteSales($offset: Int) {
  getIncompleteSales(offset: $offset) {
    id
    account_id
    account_name
    reference_code
    date
    delivery_date
    product_list
    remarks
    status
    pending_list
    paid
    balance
    total_cost
    created_at
    updated_at
  }
}`

export const GET_COMPLETE_SALES = gql`
query GetCompleteSales($offset: Int) {
  getCompleteSales(offset: $offset) {
    id
    account_id
    account_name
    reference_code
    date
    delivery_date
    product_list
    remarks
    status
    pending_list
    paid
    balance
    total_cost
    created_at
    updated_at
  }
}`
export const GET_SALES_BY_STATUS = gql`
query GetSalesByStatus($offset: Int, $status: InvoiceStatus) {
  getSalesByStatus(offset: $offset, status: $status) {
    id
    account_id
    account_name
    reference_code
    date
    delivery_date
    product_list
    remarks
    status
    pending_list
    total_cost
    paid
    balance
    created_at
    updated_at
  }
}
`
export const GET_BEST_SELLING_PRODUCTS = gql`
query GetTopSellingProducts($timeRange: TimeRange) {
  getTopSellingProducts(timeRange: $timeRange) {
    id
    name
    frequency
    total_quantity
    total_sales
  }
}
`

export const GET_ACCOUNT_SETTINGS = gql`
query GetAccountSettings($locationId: Int) {
  getAccountSettings(location_id: $locationId) {
   id
  location_id
  payables_account_id
  receiveables_account_id
  sales_income_account_id
  sales_expense_account_id
  created_at
  updated_at
  }
}
`

export const GET_TRANSACTIONS_FULL = gql`
query GetTransactions($offset: Int) {
  getTransactions(offset: $offset) {
    id
    location_id
    product_id
    customer_account_id
    customer_name
    staff_id
    vendor_account_id
    vendor_name
    accounting_year_id
    transaction_type
    type
    account_name
    code
    quantity
    unit_cost
    cost_price
    total_price
    profit
    remarks
    created_at
    updated_at
    staff_name
    location_name
    product_name
  }
}
`

export const GET_TRANSACTIONS_BY_LOCATION = gql`
  query GetTransactionsByLocation($timeRange: TimeRange) {
    getTransactionsByLocation(timeRange: $timeRange) {
      id
      name
      transaction_count
    }
  }
`;

export const GET_WEEKLY_PROFIT_COMPARISON = gql`
  query GetWeeklyProfitComparison {
    getWeeklyProfitComparison {
      currentWeekProfit
      previousWeekProfit
      profitDifference
      percentageChange
    }
  }
`;

export const GET_MONTHLY_EXPENSES_COMPARISON = gql`
  query GetMonthlyExpensesComparison {
    getMonthlyExpensesComparison {
      currentMonthExpenses
      previousMonthExpenses
      expensesDifference
      percentageChange
    }
  }
`;

export const GET_DAILY_VISITS_COMPARISON = gql`
  query GetDailyVisitsComparison($timeRange: TimeRange!) {
    getDailyVisitsComparison(timeRange: $timeRange) {
      currentPeriodAverage
      previousPeriodAverage
      visitsDifference
      percentageChange
    }
  }
`;

export const GET_AVERAGE_TRANSACTION_VALUE = gql`
  query GetAverageTransactionValue($timeRange: TimeRange!) {
    getAverageTransactionValue(timeRange: $timeRange) {
      currentAverage
      previousAverage
      difference
      percentageChange
    }
  }
`;

// export const GET_RECENT_CUSTOMERS = gql`
//   query GetRecentCustomers {
//     getRecentCustomers {
//       name
//       location
//       createDate
//       avatar
//     }
//   }
// `;
