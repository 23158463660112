import React from 'react';
import { isLoggedIn } from './Helpers/IsLoggedIn';
import { CURRENT_USER, GET_CONFIG } from './GraphQL/Queries';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Loading } from './Components/Components';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useQuery } from '@apollo/client';
import Session from './Helpers/Session';

const Login = React.lazy(() => import('./Pages/Auth/Login'));
const Register = React.lazy(() => import('./Pages/Auth/Register'));
const ForgotPassword = React.lazy(() => import('./Pages/Auth/ForgotPassword'));
const Dashboard = React.lazy(() => import('./Pages/MenuPages/Dashboard'));
const Roles = React.lazy(() => import('./Pages/MenuPages/Roles'));
const Emails = React.lazy(() => import('./Pages/MenuPages/Emails'));
const Documents = React.lazy(() => import('./Pages/MenuPages/Documents'));
const FixedAssets = React.lazy(() => import('./Pages/MenuPages/FixedAssets'));
const Inventory = React.lazy(() => import('./Pages/MenuPages/Inventory'));
const Categories = React.lazy(() => import('./Pages/MenuPages/Categories'));
const Products = React.lazy(() => import('./Pages/MenuPages/Products'));
const Batches = React.lazy(() => import('./Pages/MenuPages/Batches'));
const Locations = React.lazy(() => import('./Pages/MenuPages/Locations'));
const Users = React.lazy(() => import('./Pages/MenuPages/Users'));
const Invoices = React.lazy(() => import('./Pages/MenuPages/Invoices'));
const Profile = React.lazy(() => import('./Pages/MenuPages/Profile'));
const Finance = React.lazy(() => import('./Pages/MenuPages/Finance'));
const Accounts = React.lazy(() => import('./Pages/MenuPages/Accounts'));
const AccountingYears = React.lazy(() => import('./Pages/MenuPages/AccountingYears'));
const Transactions = React.lazy(() => import('./Pages/MenuPages/Transactions'));
const POS = React.lazy(() => import('./Pages/MenuPages/POS'));
const Assets = React.lazy(() => import('./Pages/MenuPages/Assets'));
const AssetCategories = React.lazy(() => import('./Pages/MenuPages/AssetCategories'));
const AIForecasting = React.lazy(() => import('./Pages/MenuPages/AIForecasting'));
const StockAlerts = React.lazy(() => import('./Pages/MenuPages/StockAlerts'));

const App: React.FC = () => {
  useQuery(GET_CONFIG, {
    fetchPolicy: 'cache-first',
    onCompleted: data => {
      Session.set('CONFIG', data.getConfig);
    },
  });

  useQuery(CURRENT_USER, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      Session.set('USER', JSON.stringify(data.currentUser));
    },
  });

  if (isLoggedIn()) {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<React.Suspense fallback={<Loading />}> <Login /></React.Suspense>} />
          <Route path="/dashboard" element={<React.Suspense fallback={<Loading />}> <Dashboard /></React.Suspense>} />
          <Route path="/roles" element={<React.Suspense fallback={<Loading />}> <Roles /></React.Suspense>} />
          <Route path="/emails" element={<React.Suspense fallback={<Loading />}> <Emails /></React.Suspense>} />
          <Route path="/documents" element={<React.Suspense fallback={<Loading />}> <Documents /></React.Suspense>} />
          <Route path="/fixed-assets" element={<React.Suspense fallback={<Loading />}> <FixedAssets /></React.Suspense>} />
          <Route path="/inventory" element={<React.Suspense fallback={<Loading />}> <Inventory /></React.Suspense>} />
          <Route path="/categories" element={<React.Suspense fallback={<Loading />}> <Categories /></React.Suspense>} />
          <Route path="/products" element={<React.Suspense fallback={<Loading />}> <Products /></React.Suspense>} />
          <Route path="/batches" element={<React.Suspense fallback={<Loading />}> <Batches /></React.Suspense>} />
          <Route path="/locations" element={<React.Suspense fallback={<Loading />}> <Locations /></React.Suspense>} />
          <Route path="/users" element={<React.Suspense fallback={<Loading />}> <Users /></React.Suspense>} />
          <Route path="/invoices" element={<React.Suspense fallback={<Loading />}> <Invoices /></React.Suspense>} />
          <Route path='/profile' element={<React.Suspense fallback={<Loading />}> <Profile /></React.Suspense>} />
          <Route path='/accounts' element={<React.Suspense fallback={<Loading />}> <Accounts /></React.Suspense>} />
          <Route path='/finance' element={<React.Suspense fallback={<Loading />}> <Finance /></React.Suspense>} />
          <Route path='/accounting-years' element={<React.Suspense fallback={<Loading />}> <AccountingYears /></React.Suspense>} />
          <Route path='/transactions' element={<React.Suspense fallback={<Loading />}> <Transactions /></React.Suspense>} />
          <Route path='/pos' element={<React.Suspense fallback={<Loading />}> <POS /></React.Suspense>} />
          <Route path='/assets' element={<React.Suspense fallback={<Loading />}> <Assets /></React.Suspense>} />
          <Route path='/asset-categories' element={<React.Suspense fallback={<Loading />}> <AssetCategories /></React.Suspense>} />
          <Route path='/ai-forecasting' element={<React.Suspense fallback={<Loading />}> <AIForecasting /></React.Suspense>} />
          <Route path='/stock-alerts' element={<React.Suspense fallback={<Loading />}> <StockAlerts /></React.Suspense>} />
        </Routes>
      </Router>
    );
  } else {
    return (
      <Router>
        <Routes>
          <Route path="*" element={<React.Suspense fallback={<Loading />}> <Login /></React.Suspense>} />
          <Route path="/register" element={<React.Suspense fallback={<Loading />}> <Register /></React.Suspense>} />
          <Route path="/forgot-password" element={<React.Suspense fallback={<Loading />}> <ForgotPassword /></React.Suspense>} />
        </Routes>
      </Router>
    );
  }
};

export default App;