import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { AppBar, Box, Button, IconButton, Paper, Toolbar, Typography } from '@mui/material';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import logo from '../Assets/logo.png';
import { isLoggedIn } from '../Helpers/IsLoggedIn';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import 'bootstrap/dist/css/bootstrap.min.css';


import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

export const Loading = ({ fullPage = true, message = "L.O.A.D.I.N.G", timing = false }) => {
    const [timer, setTimer] = React.useState(0);
    setTimeout(() => { setTimer(timer + 1); }, 1200);
    const myClass = fullPage ? 'loading' : '';
    return (
        <div className={myClass + ' d-flex flex-wrap align-content-center justify-content-center'}>
            <div className='text-center'>
                <div className="spinner-border text-dark  ms-2 me-2"></div>
                <div className='text-center mt-4 w3-wide'>{message}</div>
                {timing && <div className='text-center w3-small my-2'>{Math.floor(timer / 60) + '  mins '} {(timer % 60) + ' secs'} </div>}
            </div>
        </div>
    );
};


export const TopBar = ({ position = 0 }) => {
    const loggedIn = isLoggedIn();
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    const DrawerList = (
        <Box style={{ width: '75vw', maxWidth: '350px' }} role="presentation" onClick={toggleDrawer(false)}>
            <List>
                {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                {['All mail', 'Trash', 'Spam'].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );



    return (
        <>
            <Drawer anchor='right' open={open} onClose={toggleDrawer(false)}>
                {DrawerList}
            </Drawer>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar color="default">
                    <Toolbar>

                        <div className='d-flex py-2 align-items-center'>
                            <img src={logo} alt="logo" className="me-1" style={{ height: '50px' }} />
                            <div className='flex-grow-1 py-0'>
                                <h4 className='w3-wide my-0 py-0'>SYLLABUX</h4>
                                <div className='text-muted w3-small py-0 my-0'>School Management Solution</div>
                            </div>
                        </div>
                        {loggedIn && <IconButton
                            size="medium"
                            edge="start"
                            color="primary"
                            aria-label="menu"
                            className='ms-auto'
                            onClick={toggleDrawer(true)}
                        >
                            <MenuIcon />
                        </IconButton>}
                    </Toolbar>
                </AppBar>
            </Box>
        </>
    );

}

export const Footer = ({ position = 0 }) => {
    const [value, setValue] = React.useState(position);
    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
            >
                <BottomNavigationAction label="Home" icon={<CottageOutlinedIcon />} />
                <BottomNavigationAction label="Schools" icon={<AutoStoriesOutlinedIcon />} />
                <BottomNavigationAction label="Activity" icon={<WidgetsOutlinedIcon />} />
                <BottomNavigationAction label="Admin" icon={<AdminPanelSettingsOutlinedIcon />} />
                <BottomNavigationAction label="Accounts" icon={<CalculateOutlinedIcon />} />
            </BottomNavigation>
        </Paper>
    );

}